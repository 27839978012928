import React from 'react'
import Helmet from 'react-helmet'

class Meta extends React.Component {
    render() {
        const metaTitle = this.props.title
        const metaPostfix = this.props.postfix
        const metaDescription = this.props.description

        return (
            <Helmet
                htmlAttributes={{
                    lang: `cz`,
                }}
                title={`${metaTitle} | ${metaPostfix}`}
                meta={[
                    {
                        name: `description`,
                        content: metaDescription,
                    },
                ]} 
            >
            </Helmet>
        )
    }
}

export default Meta

