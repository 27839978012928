import React from 'react'
import { Link } from 'gatsby'
import { format } from 'react-string-format'

class MenuMobile extends React.Component {
    componentDidMount() {
    }

    render() {
        const menu = this.props.data

        const menuBlock = (
            <ul className="mobile-menu">
                {menu.elements.menu_items.value.map(item => (
                    <li className="mobile-menu__list-item" key={item.id}>
                        {item.elements.content.value.length > 0 ? (
                            <Link
                                to={`${'/'}${item.elements.content.value[0].elements.slug.value}`}
                                className="mobile-menu__list-item-link"
                                activeClassName="mobile-menu__list-item-link mobile-menu__list-item-link--active"
                                data-tracking-label={format('MobileMenu_{0}', item.elements.title.value)}
                            >
                                {item.elements.title.value}
                            </Link>
                        ) : ""}
                    </li>
                ))}
            </ul>
        )

        return <nav className="menu">{menuBlock}</nav>
    }
}

export default MenuMobile
