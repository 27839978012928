import React, { useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby'
import Cta from '../Cta'
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { format } from 'react-string-format'

const footer = ({ isContactPage }) => {
    useEffect(() => {
        // Mount

        // Unmount
        return () => {
        }
    }, [])

    const data = useStaticQuery(graphql`
query FooterQuery {
  kontentItemSiteMetadata(system: { codename: { eq: "site_metadata" } }) {
    elements {
      title {
        value
      }
      footer_testimonial_title {
        value
      }
      footer_testimonial_text {
        value
      }
      footer_testimonial_author {
        value
      }
      footer_contact_title {
        value
      }
    }
  }
  kontentItemContactPage(system: { codename: { eq: "kontakt" } }) {
    id
    system {
      id
    }
    elements {
      name {
        value
      }
      email {
        value
      }
      phone {
        value
      }
      name_secondary {
        value
      }
      email_secondary {
        value
      }
      phone_secondary {
        value
      }
    }
  }
}
`
    )

    const meta = data.kontentItemSiteMetadata.elements
    const contact = data.kontentItemContactPage.elements
    const today = new Date();
    const isContact = isContactPage

    return (
        <div>
            <section className="footer-content footer">
                <div className="main-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="footer__title">
                                    <h2>{meta.footer_contact_title.value}</h2>
                                </div>
                                <div className="footer__contact-title">České/mezinárodní hovory:</div>
                                <ul className="more-info">
                                    <li>Tel: <a href={format('tel:{0}', contact.phone_secondary.value)} data-tracking-label="Footer_Kontakt_Phone_Secondary">{contact.phone_secondary.value}</a></li>
                                    <li>Email: <a href={format('mailto:{0}', contact.email_secondary.value)} data-tracking-label="Footer_Kontakt_Email_Secondary">{contact.email_secondary.value}</a></li>
                                </ul>
                                <div className="footer__contact-title">České hovory:</div>
                                <ul className="more-info">
                                    <li>Tel: <a href={format('tel:{0}', contact.phone.value)} data-tracking-label="Footer_Kontakt_Phone">{contact.phone.value}</a></li>
                                    <li>Email: <a href={format('mailto:{0}', contact.email.value)} data-tracking-label="Footer_Kontakt_Email">{contact.email.value}</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer__title">
                                    <h2>{meta.footer_testimonial_title.value}</h2>
                                </div>
                                <div className="testimonial">
                                    <blockquote className="testimonial__quote">
                                        <RichTextElement
                                            value={meta.footer_testimonial_text.value} />
                                    </blockquote>
                                    <p className="testimonial__customer">{meta.footer_testimonial_author.value}</p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="sub-footer">
                                    <p>Copyright © {today.getFullYear()} <a href="/" data-tracking-label="Footer_Copyright_OMne">Intersystplus</a>. Všechna práva vyhrazena.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default footer
