import React, { useEffect } from 'react'
import { StaticQuery, graphql } from "gatsby"
import Helmet from 'react-helmet'
import '../../assets/scss/init.scss'
import KontentSmartLink from '@kentico/kontent-smart-link';
import '@kentico/kontent-smart-link/dist/kontent-smart-link.styles.css'

const Layout = ({ children }) => {
    useEffect(() => {

        handleClickEvents()

        const plugin = KontentSmartLink.initialize({
            queryParam: 'preview-mode'
        });
        return () => {
            plugin.destroy();
        };
    });

    return (
        <StaticQuery
            query={graphql`
        {
          sitePlugin(name: {eq: "@kentico/gatsby-source-kontent"}) {
            pluginOptions {
              projectId
              languageCodenames
            }
          }
        }
      `}
            render={data => (
                <div className="layout"
                    data-kontent-project-id={data.sitePlugin.pluginOptions.projectId}
                    data-kontent-language-codename={data.sitePlugin.pluginOptions.languageCodenames[0]}
                >
                    <Helmet defaultTitle="KartarkaBrno.cz">
                        <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
                    </Helmet>
                        {children}

                </div>
            )}
        ></StaticQuery>
    )

    // GA onClick event tracking
    function handleClickEvents() {

        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }

        var a = document.querySelectorAll('a[data-tracking-label]');
        a.forEach(function (element, index) {
            // current DOM element
            var label = toTitleCase(element.getAttribute("data-tracking-label").trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("&", "a").replace(",", " ").replace("-", " ")).replace(" ", "").replace(",", "").replace(".", ""); // remove diacritics and remove spaces
            if (label != '') {
                element.addEventListener('click', function (e) {
                    if (e.which && (e.which === 1 || e.which === 2)) {
                        gtag('event', 'Click', { 'event_category': 'Link', 'event_label': label })
                    }
                });
            }
        });
    }

    function toTitleCase(str) {
        str = str.split(' ');
        for (var i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join('');
    };
}


export default Layout
