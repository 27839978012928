import React, { useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby'
import Menu from '../Menu'
import MenuMobile from '../MenuMobile'
import Logo from "../../assets/images/logo.png";

const header = ({ isContactPage }) => {
    useEffect(() => {
        // Mount
        // Unmount
        return () => {
        }
    }, [])

    const data = useStaticQuery(graphql`
  query HeaderQuery {
    kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
      elements {
        title {
          value
        }
        domain {
          value
        }
      }
    }
    kontentItemMenu(system: {codename: {eq: "navigation_menu"}}) {
    elements {
      menu_items {
        value { 
          ... on kontent_item_menu_item {
            system {
              id
            }
            id
            elements {
              title {
                value
              }
              content {
                value {
                  ... on kontent_item_contact_page {
                    elements {
                      slug {
                        value
                      }
                    }
                  }
                  ... on kontent_item_simple_page {
                    elements {
                      slug {
                        value
                      }
                    }
                  }
                  ... on kontent_item_services_overview_page {
                    elements {
                      slug {
                        value
                      }
                    }
                  }
                }
              }   
            }
          }
        }
      }
    }
  }
}
`
    )

    const menu = data.kontentItemMenu
    const isContact = isContactPage

    return (
        <div>
            <div className="mobile-nav-wrapper">
                <div className="mobile-menu-inner">
                    <MenuMobile data={menu} />
                </div>
            </div>
            <div className="mobile-menu-overlay" onClick={toggleMobileMenu}></div>
            <nav className="container--full menu menu-js">
                <div className="container">
                    <div className="row">
                        <div className="col-xxs-24"><div className="menu__logo">
                            <a href="/" title="Na úvodní stránku">
                                <img src={Logo} alt="Logo Intersystplus" />
                            </a>
                        </div>
                            <Link to="#" className="menu-bar" id="menu-show-mobile-nav" data-tracking-label="Header_Hamburger" onClick={toggleMobileMenu}>
                                <span className="hamburger"></span>
                            </Link>
                            <Menu data={menu} />
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )

    // Mobile Nav
    function toggleMobileMenu() {

        var openMobileNav = document.querySelector('.menu-bar'),
            mobileNav = document.querySelector('.mobile-nav-wrapper'),
            overlay = document.querySelector('.mobile-menu-overlay'),
            mobileNavOpened = document.querySelector('.mobile-nav-wrapper.is-open'),
            overlayOpened = document.querySelector('.mobile-menu-overlay.is-open')

        // Open Mobile Nav
        if (!mobileNavOpened && !overlayOpened) {
            openMobileNav.classList.add("active");
            mobileNav.classList.add("is-open");
            overlay.classList.add("is-open");
        }

        // Close Mobile Nav
        if (overlayOpened) {
            openMobileNav.classList.remove("active");
            mobileNav.classList.remove("is-open");
            overlay.classList.remove("is-open");
        }
    }
}

export default header
